<template>
  <div class="bookingBlock__content">
      <div class="confirmationBooking">
        <div class="confirmationBooking__content">
          <div class="confirmationBooking__content-left" >
            <div :ops="ops">
              <form>
                <button
                  class="confirmationBooking__button js-popup"
                  type="button"
                  @click="confirmBooking"
                >
                  <span class="text">Confirm</span>
                </button>
              </form>
            </div>
          </div>
          <div class="confirmationBooking__content-right">
            <div class="confirmationBooking__content-right-inner">
              <div class="confirmationBooking__info">
                <div class="confirmationBooking__info-text first">Click to confirm your request.<br/><br/></div>
                <div class="confirmationBooking__info-text">Unless labeled “Book Now” - in which case pricing is exact - listed pricing per aircraft is estimated and/or approximate.  Pricing will be confirmed by the FLYJETS team upon receipt of request and quote retrieval.
                </div>
              </div>
              <!-- <span class="confirmationBooking__link">
                <span class="confirmationBooking__link-icon"><SvgIcon name="printer"/></span>
                <span class="confirmationBooking__link-text">Print this information</span>
              </span> -->
            </div>
          </div>
        </div>
    </div>
    <InitiateTransactionPopUp @continue="onContinue" />
    <CompleteTransactionPopUp @success="onSuccess" @error="onError" />
    <InfoPopup @success="okClicked"/>
  </div>
</template>

<script>
  import { bookingAPI } from '@/api/booking'
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import InitiateTransactionPopUp from '@/components/common/modals/InitiateTransactionPopUp'
  import CompleteTransactionPopUp from '@/components/common/modals/CompleteTransactionPopUp'

  import './Confirmation.styl'

  export default {
    name: 'confirmation',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      bookingType: null,
      isBookableDemo: false,
      isFloatingFleet: false,
      price: 0,
      infoPopupForTerms: false,
    }),
    computed: {
      currentBilling () {
        return this.$store.getters.getCurrentBilling
      },
      bookingData () {
        return this.$store.state.bookingFromSearch
      },
    },
    created () {
      // console.log(this.$store.state.bookingFromSearch.fullBookingItem)
      const { bookingType, bookableDemo, isFloatingFleet } = this.$store.state.bookingFromSearch.fullBookingItem

      this.bookingType = bookingType === 2 || bookingType === 16
        ? 'aircraft'
        : bookingType === 4 || bookingType === 32
        ? 'spaces'
        : null;
      this.isBookableDemo = bookableDemo;
      this.isFloatingFleet = isFloatingFleet;
    },
    methods: {
      onContinue () {
        this.$modal.hide('initiate')
        this.$modal.show('complete', { transactionType: 'booking', price: this.price })
      },
      okClicked () {
        if (this.infoPopupForTerms) {
          this.infoPopupForTerms = false;
        } else {
          this.$router.push('/map-search');
        }
      },
      async onSuccess () {
        this.$modal.hide('complete');
        const flyGreenData = this.$store.state.flygreen.offsetPurchasePayload;
        await this.$store.dispatch(
           'confirmBooking',
            flyGreenData,
        );
        this.$modal.show('InfoPopup', {
          title: 'Payment Received',
          text: 'We will be in touch shortly with your requested availabilities.',
        })
        this.$store.commit('changeTerms', false);
        console.log('success')
      },
      onError (error) {
        this.$modal.hide('complete')
        this.$modal.show('InfoPopup', {
          title: 'Error',
          text: error.message,
        });
        console.log('error')
      },
      async confirmBooking () {
        if (!this.$store.state.bookingFromSearch.terms) {
          this.infoPopupForTerms = true;
          this.$modal.show('InfoPopup', {
            text: 'Please accept the terms before confirming.',
          });
          // window.alert('Please accept the terms before confirming!')
          return
        }
        if (this.bookingType === 'aircraft') {
          // console.log('made it here')
          await bookingAPI.getBookingPaymentInfo().then((res) => {
            this.price = res.data;
          });
          this.$modal.show(
            'initiate',
            { transactionType: 'booking', isBookableDemo: this.isBookableDemo, isFloatingFleet: this.isFloatingFleet, price: this.price },
          )
        } else if (this.bookingType === 'spaces') {
          const flyGreenData = this.$store.state.flygreen.offsetPurchasePayload;
          await this.$store.dispatch(
           'confirmBooking',
            flyGreenData,
        );

          let header = 'Booking Request Confirmed';
          let msg = 'We have received your request and will facilitate a match on your requested route on an aircraft option within this floating fleet.'
          if (!this.isBookableDemo && !this.isFloatingFleet) {
            header = 'Booking Request Confirmed';
            msg = 'We have received your request and will facilitate a match on your requested route with this option or similar.'
          } else if (this.isBookableDemo) {
            header = 'Booking Request Confirmed';
            msg = 'We have received your request and will facilitate a match on your requested route on an aircraft similar to this bookable demonstration.'
          }

          this.$modal.show('InfoPopup', {
            title: header,
            text: msg,
          });
        } else {
          console.log('Something went wrong.')
        }
        this.$store.commit('clearTravelers')
      },
    },
    components: {
      InitiateTransactionPopUp,
      CompleteTransactionPopUp,
      InfoPopup,
    },
  }
</script>
